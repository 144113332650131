import ArrayStore from 'devextreme/data/array_store';
import {ServiceFlags, DEVICEFlags, LAVAGEFlags} from '../../views/pages/Device/ConfigData';

const Devices = [];

const Logs = [];

const AllDevices = [];

export const DevicesStoreLog = new ArrayStore({
  key: 'Id',
  data: Logs,
   });

export const AllDevicesStore = new ArrayStore({
  key: 'POSNumber',
  data: AllDevices,
   });

export function RemoveDevice(DevNum) {
  // Removes the data item with both "field1" and "field2" being equal to 1
  //DevicesStore.remove(DevNum);
  //DevicesStore.push();
  AllDevicesStore.push([{
    type: 'remove',
    key: DevNum,
  }]);
    };

function handleCheck(val) {
  const isFound =  AllDevices.some(item => val.POSNumber === item.POSNumber);

  return isFound;
    };

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
    };

export function GetDevices(UserData) {

  const requestGetOptions = {
    method: 'GET',
    mode:'cors',
    cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
          'Accept': 'application/json, text/plain, */*',
            "Content-type": "application/json; charset=UTF-8",
            "Token":UserData.LoginInfo.Token
        },
       

};

    Devices.length = 0; 

    var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/Modbus/GetPosParkFullStatus";

 return fetch(URL, requestGetOptions)
  .then((response) => response.json())
  .then(function(data){ 

    var Resjson = JSON.parse(data);

    var MenuName = "Select Device";
    Devices.push(MenuName);
    Resjson.map(function(object, i){
      //console.log(object);
      //TO DO PUSH DEVICE TO ARRAY

      /*POS = 0,
        PIL = 1,
        HORODATEUR = 2,
        HORODATEUR_CCB = 4,
        ENTRY = 8,
        EXIT = 16,
        LAVAGE = 32,
        PIETON = 64,
        VALIDEUR_CCB = 128,
        PREHAU = 256 */
      switch(object.StationType)
      {
        case 0:

          var name = "POS "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 1:
          var name = "PIL "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 2:
          var name = "HORODATEUR "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 4:
          var name = "HORODATEUR_CCB "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 8:
          var name = "ENTRY "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 16:
          var name = "EXIT "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 32:
          var name = "LAVAGE "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 64:
          var name = "PIETON "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 128:
          var name = "VALIDEUR_CCB "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;

        case 256:
          var name = "PREHAU "+ zeroPad(object.DeviceNumber,2);
          var options=
            { label: name, value: name };
            Devices.push(options);
        break;
      }

      

      
      
  });

    return Devices;
  }).catch((err) => 
  {
  //console.log(err.message);
  });

   
    };

export function Clear(){
  Logs.length = 0;

  //DevicesStoreLog.clear();

  DevicesStoreLog._array = [];
    };

export function GetLogs(UserData, LogReq) {
  Logs.length = 0;
  DevicesStoreLog._array = [];
  var myJSON = JSON.stringify(LogReq);

  const requestPostOptions = {
    method: 'POST',
    mode:'cors',
    cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
          'Accept': 'application/json, text/plain, */*',
            "Content-type": "application/json; charset=UTF-8",
            "Token":UserData.LoginInfo.Token
        },
        body: myJSON
  
  };
  

var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/SingleSite/GetLogs";

  fetch(URL, requestPostOptions)
  .then((response) => response.json())
  .then(function(data){ 

    var Resjson = JSON.parse(data);

    
    Resjson.map(function(object, i){
      console.log(object);
      //TO DO PUSH DEVICE TO ARRAY

      
      var item = {
        Id:object.Id,
        TimeStamp:object.TimeStamp,
        Level: object.Level,        
        Message: object.Message,
        
      }
      DevicesStoreLog.push([{ type: 'insert', data: item }]);

     /* DevicesStoreLog.push([{
        type: 'update',
        key: object.Id,
        data: {
          Id:object.Id,
            TimeStamp:object.TimeStamp,
            Level: object.Level,        
            Message: object.Message,
            
        },
      }]);*/
      
      
  });

    //return Logs;
  }).catch((err) => 
  {
  //console.log(err.message);
  });

   
    };

function getActiveValues(decimalValue, Flags){
    const activePermissions = [];
  
    for (const [key, value] of Object.entries(Flags)) {
        if (decimalValue & value) {
            activePermissions.push(value);
        }
    }
  
    return activePermissions;
    };
  
export function GetAllConfig(UserData) {
    AllDevices.length = 0;
  
  
    const requestGetOptions = {
      method: 'GET',
      mode:'cors',
      cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          headers: {
            'Accept': 'application/json, text/plain, */*',
              "Content-type": "application/json; charset=UTF-8",
              "Token":UserData.LoginInfo.Token
          },
         
  
  };
  
  
    var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/SingleSite/GetDevicesConfig";
    
     return fetch(URL, requestGetOptions)
      .then((response) => response.json())
      .then(function(data){ 
    
        var Resjson = JSON.parse(data);
    
        
        Resjson.map(function(object, i){
          
          //TO DO PUSH DEVICE TO ARRAY
          //console.log("CODE ORIGINAL", object.ServiceOption);
          object.ServiceOption = getActiveValues(object?.ServiceOption, ServiceFlags);
          object.DEVICES = getActiveValues(object?.DEVICES, DEVICEFlags);
          object.LAV_OPTIONS = getActiveValues(object?.LAV_OPTIONS, LAVAGEFlags);
          //console.log("CODE BIT FLAG", object.ServiceOption);
          //console.log("BACK TO VALUE", sumArray( object.ServiceOption));
          
          //var CODE = object.CODE_INDEX_LIST.toString();
          //console.log("CODE LIST INDEX", CODE);
          //object.CODE_INDEX_LIST = CODE;
          //object.ADDR_INDEX_LIST = object.ADDR_INDEX_LIST.join(",");

         // AllDevices.push(object);
          
          if(handleCheck(object))
          {

            console.log("handleCheck",object);

              AllDevicesStore.push([{
              type: 'update',
              key: object.POSNumber,
              totalCount: Resjson.length,
              data: object,
          }]);        
          }
          else{

            console.log("No handleCheck",object);
            AllDevices.push(object);
            
          }
         
          
      });
    
        //return AllDevices;
      }).catch((err) => 
      {
      console.log(err.message);
      });
    
       
    };

export function SendOperation(UserData, ConfigInfo) {

  var myJSON = JSON.stringify(ConfigInfo);

  console.log("JSON Config Info to update", myJSON);

  const requestOptions = {
        method: 'POST',
        mode:'cors',
        cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
              'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json; charset=UTF-8",
                "Token":UserData.LoginInfo.Token
            },
            body: myJSON

};
var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/SingleSite/Update";
  
   return fetch(URL, requestOptions)
  .then((response) => response.json())
  .then(function(data){ 
    console.log("UPDATE RESPONSE: ",data);
   // var json = JSON.parse(data);
   // console.log("UPDATE RESPONSE: ",data);
    return data;
    
  }).catch((err) => 
  {
  console.log(err.message);
  });



  
    };

export function GetAllDevices(UserData) {
  AllDevices.length = 0;


  const requestGetOptions = {
    method: 'GET',
    mode:'cors',
    cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
          'Accept': 'application/json, text/plain, */*',
            "Content-type": "application/json; charset=UTF-8",
            "Token":UserData.LoginInfo.Token
        },
       

};

  var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/Modbus/GetDeviceIPs";
  
   return fetch(URL, requestGetOptions)
    .then((response) => response.json())
    .then(function(data){ 
  
      var Resjson = JSON.parse(data);
  
      
      Resjson.map(function(object, i){
        console.log(object);
        //TO DO PUSH DEVICE TO ARRAY
        AllDevices.push(object);
        
       
        
    });
  
      return AllDevices;
    }).catch((err) => 
    {
    //console.log(err.message);
    });
  
     
    };

export function AddNewDevice(deviceIp,UserData) {
      
      const requestPostOptions = {
        method: 'POST',
        mode:'cors',
        cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
              'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json; charset=UTF-8",
                "Token":UserData.LoginInfo.Token
            },
            
      
      };
      
    
    var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/Modbus/AddIP" + "(" + deviceIp + ")";
    
      fetch(URL, requestPostOptions)
      .then((response) => response.json())
      .then(function(data){ 
    
        var Resjson = JSON.parse(data);
    
        console.log("Delete Result" + Resjson);
        //return Logs;
      }).catch((err) => 
      {
        console.log(err.message);
      });
    
       
    };   

export function DeleteDevice(deviceIp,UserData) {
      
      const requestPostOptions = {
        method: 'POST',
        mode:'cors',
        cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
              'Accept': 'application/json, text/plain, */*',
                "Content-type": "application/json; charset=UTF-8",
                "Token":UserData.LoginInfo.Token
            },
            
      
      };
      
    
    var URL = "http://" + UserData.Ip + ":8733/PARK_STATUS_WEBSERVICE/Modbus/RemoveIP" + "(" + deviceIp + ")";
    
      fetch(URL, requestPostOptions)
      .then((response) => response.json())
      .then(function(data){ 
    
        var Resjson = JSON.parse(data);
    
        console.log("Delete Result" + Resjson);
        //return Logs;
      }).catch((err) => 
      {
        console.log(err.message);
      });
    
       
    };    
  


