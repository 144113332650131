import Pos from'../../../assets/images/POSimage.png';
import BarrierExitClose from'../../../assets/images/BarrierEntryClose.png';

// Function convert an array of int value to single sum value 
export const sumArray = (arr) => {
  try
  {
    return arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
  catch
  {
    return arr;
  }
 
};

// Function to check if a specific flag is set
export  const isBitSet = (flag, bitPosition) => {
  if(flag == 0 && bitPosition == 0)
    return true;
 return (flag & bitPosition);

}
 
// Function to check if a specific flag is set
export const isFlagSet = (flags, flag) => {
    return (flags & flag) === flag;
};


export const LANGUAGE = {
  options: [
  {name: 'English', id: 1},
  {name: 'Francais', id: 2},
]
};

export const TRUEFALSE = {
  options: [
  {name: 'false', id: 1, value:false},
  {name: 'true', id: 2, value:true},
]
};
export const TRUEFALSEFlags = {
 
  FALSE: 1 << 0, // 1
  TRUE: 1 << 1, // 2
  
};

export const GENDER = {
  options: [
  {name: 'MALE', id: 0},
  {name: 'FEMALE', id: 1},
]
};
// Function to determine if an item should be disabled
export const ServiceDisabled = (item, StationType) => {

  if (isBitSet(sumArray(item), ServiceFlags.EVENT | ServiceFlags.MAPPLEQR | ServiceFlags.HYBRID | ServiceFlags.COUPVAL | ServiceFlags.SEPAQ)
       && isBitSet(StationType, CASHDESKTYPEFlags.WASHSTATION))
       return true;

  if (isBitSet(sumArray(item), ServiceFlags.OZERO | ServiceFlags.SEPAQ | ServiceFlags.MAPPLEQR)
       && isBitSet(StationType, CASHDESKTYPEFlags.POS))
       return true;

  if (isBitSet(sumArray(item), ServiceFlags.OZERO | ServiceFlags.SEPAQ | ServiceFlags.EVENT | ServiceFlags.COUPVAL | ServiceFlags.HYBRID)
       && isBitSet(StationType, CASHDESKTYPEFlags.HORO))
       return true;

  if (isBitSet(sumArray(item), ServiceFlags.OZERO | ServiceFlags.MAPPLEQR)
       && isBitSet(StationType, CASHDESKTYPEFlags.PIL))
       return true;

  if (isBitSet(sumArray(item), ServiceFlags.MAPPLEQR | ServiceFlags.MAPPLEQR | ServiceFlags.SEPAQ | ServiceFlags.COUPVAL | ServiceFlags.EVENT)
       && isBitSet(StationType, CASHDESKTYPEFlags.ENTRY))
       return true;              
     
  return false;  
};

export const DevicesDisabled = (item, StationType) => {
  
  if (isBitSet(sumArray(item), DEVICEFlags.CASH | DEVICEFlags.COIN | DEVICEFlags.VOICES)
    && isBitSet(StationType, CASHDESKTYPEFlags.WASHSTATION | CASHDESKTYPEFlags.HORO))
    return true;

  if (isBitSet(sumArray(item), DEVICEFlags.CASH | DEVICEFlags.COIN)
    && isBitSet(StationType, CASHDESKTYPEFlags.PIL))
    return true;

  if (isBitSet(sumArray(item), DEVICEFlags.CASH | DEVICEFlags.COIN)
    && isBitSet(StationType, CASHDESKTYPEFlags.ENTRY))
    return true;

  if (isBitSet(sumArray(item), DEVICEFlags.VOICES)
    && isBitSet(StationType, CASHDESKTYPEFlags.POS))
    return true;


  return false;
};

export const SiteOptDisabled = (item, StationType) => {
  
  
  if (isBitSet(sumArray(item), OPT_SITEFlags.WEBFEESERVICE)
    && isBitSet(StationType, CASHDESKTYPEFlags.POS))
    return true;

  if (isBitSet(sumArray(item), OPT_SITEFlags.WEBFEESERVICE)
    && isBitSet(StationType, CASHDESKTYPEFlags.PIL))
    return true;

  if (isBitSet(sumArray(item), OPT_SITEFlags.WEBFEESERVICE | OPT_SITEFlags.MULTILEVEL)
    && isBitSet(StationType, CASHDESKTYPEFlags.ENTRY))
    return true;

  /*if (isBitSet(sumArray(item), OPT_SITEFlags.MULTILEVEL)
    && isBitSet(StationType, CASHDESKTYPEFlags.HORO))
    return true;

  if (isBitSet(sumArray(item),  OPT_SITEFlags.SINGLELEVEL)
    && isBitSet(StationType, CASHDESKTYPEFlags.HORO))
    return true;*/


  return false;
};

export const isStationDisabled = (item, Station) => {
 
    item.disabled = DevicesDisabled(item.id, Station);
    
  
  return item;
  
};

export const isDeviceDisabled = (item, Station) => {
 
  item.disabled = ServiceDisabled(item.id, Station);
  

return item;

};

export const CASHDESKTYPE = {
  options: [
  {name: 'PAY STATION', id: 0, ImageSrc:Pos},
  {name: 'PAY IN LANE', id: 1, ImageSrc:BarrierExitClose},
  {name: 'HORODATOR', id: 2, ImageSrc:Pos},
  //{name: 'HORODATEUR CCB', id: 4, ImageSrc:Pos},
  {name: 'ENTRY STATION', id: 8, ImageSrc:Pos},
  //{name: 'EXIT STATION', id: 16, ImageSrc:Pos},
  {name: 'WASH STATION', id: 32, ImageSrc:Pos},
  //{name: 'PIETON', id: 64, ImageSrc:Pos},
  //{name: 'VALIDEUR CCB', id: 128, ImageSrc:Pos},
  //{name: 'PREHAU', id: 256, ImageSrc:Pos},
]
};
export const CASHDESKTYPEFlags = {
    
  POS: 0 << 0,
  PIL: 1 << 0, // 1
  HORO: 1 << 1, // 2
  //HORO: 1 << 2, // 4
  ENTRY: 1 << 3,  // 8
  EXIT: 1 << 4,  // 16
  WASHSTATION: 1 << 5,  // 32
  
  
};

export const PaymentProvider = {
    options: [
    {name: 'NONE', id: 0},
    //{name: 'SixCard', id: 1},
   // {name: 'CreditCall', id: 2},
   // {name: 'MONERIS', id: 4},
    {name: 'GLOBAL', id: 8},
   
    
  ]
  };

  export const HORO_OPT = {
    options: [
    {name: 'NONE', id: 0},
    {name: 'ETE', id: 1},
    {name: 'HIVER', id: 2},
  ]
  };

  
 
  export const POS_SERVICE = (Station) => {
    const options = [
    
      {name: 'EVENT', id: 1, disabled: ServiceDisabled(1, Station)},
      //{name: 'REBATE', id: 2},
      //{name: 'ONLINE', id: 4},
      //{name: 'HOTEL', id: 8},
      //{name: 'LOSTTICKET', id: 16},
      //{name: 'DEMOMODE', id: 32, disabled: false},
      {name: 'DEBUGMODE', id: 64, disabled: ServiceDisabled(64, Station)},
      //{name: 'WEBHOST', id: 128},
      {name: 'DOOR QRCODE', id: 256, disabled: ServiceDisabled(256, Station)},
      //{name: 'BARCODE', id: 512},
      {name: 'HYBRID', id: 1024, disabled: ServiceDisabled(1024, Station)},
      //{name: 'BARCODEOVER', id: 2048},
      //{name: 'UX RESEND', id: 4096},
      {name: 'COUPON', id: 8192, disabled: ServiceDisabled(8192, Station)},
      {name: 'SEPAQ', id: 16384, disabled: ServiceDisabled(16384, Station)},
      //{name: 'FULLDAY', id: 32768},
      {name: 'OZERO', id: 65536, disabled: ServiceDisabled(65536, Station)}
    ]
    
    return options;
};

export const OPT_SITE = (Station) => {
  const options = [
  {name: 'SINGLE LEVEL', id: 0, disabled: SiteOptDisabled(0, Station)},
  {name: 'WEB FEE SERVICE', id: 8, disabled: SiteOptDisabled(8, Station)},
  {name: 'MULTI LEVEL', id: 16, disabled: SiteOptDisabled(16, Station)},
]
return options;
};
export const OPT_SITEFlags = {
  SINGLELEVEL: 0 << 0,
  WEBFEESERVICE: 1 << 3,  // 8
  MULTILEVEL: 1 << 4,  // 16
  
};

export const POS_DEVICE = (Station) => {
  const options = [
  {name: 'PRINTER', id: 1, disabled: DevicesDisabled(1, Station)},
  {name: 'CASH', id: 2, disabled: DevicesDisabled(2, Station)},
  {name: 'COIN', id: 4, disabled: DevicesDisabled(4, Station)},
  {name: 'CREDITCARD', id: 8, disabled: DevicesDisabled(8, Station)},
  {name: 'BARCODE', id: 16, disabled: DevicesDisabled(16, Station)},
  {name: 'IO', id: 32, disabled: DevicesDisabled(32, Station)},
  //{name: 'GATE', id: 64, disabled: DevicesDisabled(64, Station)},
  //{name: 'KB', id: 128, disabled: DevicesDisabled(128, Station)},
  {name: 'VOICES', id: 256, disabled: DevicesDisabled(256, Station)},
  {name: 'INTERCOM', id: 512, disabled: DevicesDisabled(512, Station)},
]
return options;
};
export const DEVICEFlags = {
  //None: 0,
  PRINTER: 1 << 0, // 1
  CASH: 1 << 1, // 2
  COIN: 1 << 2, // 4
  CREDITCARD: 1 << 3,  // 8
  BARCODE: 1 << 4,  // 16
  IO: 1 << 5,  // 32
  //DEBUGMODE: 1 << 6,  // 64
  KB: 1 << 7,  // 128
  VOICES: 1 << 8,  // 256
  INTERCOM: 1 << 9.  // 512
  //HYBRID: 1 << 10,  // 1024
  //HYBRID: 1 << 11  // 2048
  //HYBRID: 1 << 12  // 4096
  //COUPVAL: 1 << 13,  // 8192
  //SEPAQ: 1 << 14,  // 16384
  //HYBRID: 1 << 15  // 32768
  //OZERO: 1 << 16  // 65536
};
  
export const ServiceFlags = {
    //None: 0,
    EVENT: 1 << 0, // 1
    //DEMOMODE: 1 << 1, // 2
    //MAPPLEQR: 1 << 2, // 4
    //HYBRID: 1 << 3  // 8
    //HYBRID: 1 << 4  // 16
    DEMOMODE: 1 << 5,  // 32
    DEBUGMODE: 1 << 6,  // 64
    //HYBRID: 1 << 7  // 128
    MAPPLEQR: 1 << 8,  // 256
    //HYBRID: 1 << 9  // 512
    HYBRID: 1 << 10,  // 1024
    //HYBRID: 1 << 11  // 2048
    //HYBRID: 1 << 12  // 4096
    COUPVAL: 1 << 13,  // 8192
    SEPAQ: 1 << 14,  // 16384
    //HYBRID: 1 << 15  // 32768
    OZERO: 1 << 16  // 65536
  };

export const LAVAGE_OPTION = {
    options: [
    {name: 'NONE', id: 0},
    {name: 'SELECTION', id: 1},
    {name: 'VIDEO', id: 2},
    {name: 'QUESTION', id: 4},
    {name: 'FORFAIT', id: 8},
    {name: 'LAVAGE', id: 16},
    {name: 'MULTI', id: 32},
    {name: 'MAP', id: 64},
    {name: 'REPRINT', id: 128},
    {name: 'PASSCODE', id: 256},
    {name: 'INFO2', id: 512},
    {name: 'ANNUAL', id: 1024},
    {name: 'LAVONLY', id: 2048},
    {name: 'FEESELECT', id: 4096},
    {name: 'SCANSELECT', id: 8192},
  ]
  };
export const LAVAGEFlags = {
    
    SELECTION: 1 << 0, // 1
    VIDEO: 1 << 1, // 2
    QUESTION: 1 << 2, // 4
    FORFAIT: 1 << 3,  // 8
    LAVAGE: 1 << 4,  // 16
    MULTI: 1 << 5,  // 32
    MAP: 1 << 6,  // 64
    REPRINT: 1 << 7,  // 128
    PASSCODE: 1 << 8,  // 256
    INFO2: 1 << 9,  // 512
    ANNUAL: 1 << 10,  // 1024
    LAVONLY: 1 << 11,  // 2048
    FEESELECT: 1 << 12,  // 4096
    SCANSELECT: 1 << 13,  // 8192
    
  };

  

  export const PRINTER_DEVICES = {
    options: [
    {name: 'NONE', id: 0},
    {name: 'CUSTOM', id: 1},
  ]
  };

  export const PRINTER_MODEL = {
    options: [
    {name: 'CUSTOM KPM180H', id: 1},
    {name: 'CUSTOM VKP80III', id: 2},
  ]
  };

  export const PRINTER_CONNECTION = {
    options: [
    //{name: 'USB', id: 1},
    {name: 'IP', id: 2},
    {name: 'RS232', id: 4}
  ]
  };

  export const TICKET_OUTPUT = {
    options: [
    {name: 'EJECT', id: 69},
    {name: 'RETRACT', id: 82},
  ]
  };

  export const CASH_DEVICES = {
    options: [
    {name: 'NONE', id: 0},
    {name: 'CASHFLOW', id: 1},
    //{name: 'BNR', id: 2}
  ]
  };

  export const COIN_DEVICES = {
    options: [
    {name: 'NONE', id: 0},
    {name: 'NRI_C2', id: 1},
    {name: 'GRYPHON', id: 2}
  ]
  };

  export const CREDIT_DEVICES = {
    options: [
    {name: 'NONE', id: 0},
    //{name: 'IDTECH', id: 1},
    //{name: 'MAGTEK', id: 2},
    //{name: 'UX', id: 4},
    //{name: 'E355', id: 8},
    {name: 'UPT1000', id: 16},
    //{name: 'TPE', id: 32},
  ]
  };
  export const CREDIT_DEVICESFlags = {
    //None: 0,
    IDTECH: 1 << 0, // 1
    MAGTEK: 1 << 1, // 2
    UX: 1 << 2, // 4
    E355: 1 << 3,  // 8
    UPT1000: 1 << 4,  // 16
   
  };

  export const BARCODE_DEVICES = {
    options: [
    {name: 'NONE', id: 0},
    {name: 'GFS4400', id: 1},
    {name: 'SYMBOL', id: 2},
  ]
  };

  export const IO_DEVICES = {
    options: [
    {name: 'NONE', id: 0},
    //{name: 'AXISAT', id: 1},
    //{name: 'ICP', id: 2},
    {name: 'TELE', id: 4},
    {name: 'CLICK', id: 8},
  ]
  };