import React, { useState }from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'

import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { connect } from "react-redux";
import { update } from "src/actions";

const AppSidebar = (LogUser) => {


  if (LogUser)
  {
    //console.log("authenticated " + isAuthenticated);
    console.log("AppSidebar authenticated " + LogUser.isAuthenticated);
    //console.log(LogUser.UserData.Token);
    //var Resjson = JSON.parse(LogUser.UserData);
   

   
  }


  const dispatch = useDispatch()
  //const unfoldable = useSelector((state) => LogUser.SidebarUnfoldable)
  //const sidebarShow = useSelector((state) => LogUser.SidebarShow)

  //console.log("STATE unfoldable " + unfoldable);
  //console.log("STATE sidebarShow " + sidebarShow);
  const [visible, setVisible] = useState(true);
  const [Unfoldable, setUnfoldable] = useState(false);
  return (
    <CSidebar
      position="fixed"
      unfoldable={false}
      visible={true}
      //onVisibleChange={(visible) => {
        //setVisible(!visible)
      //}}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={LogUser.UserData.Nav}  />
        </SimpleBar>
      </CSidebarNav>
      {/*<CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => setUnfoldable( !Unfoldable )}
      />*/}
    </CSidebar>
  )
}

export default connect(state => ({
  isAuthenticated: state.auth.isAuthenticated,
  UserData: state.auth.UserData,
 
}))(React.memo(AppSidebar));
