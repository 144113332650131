import React from 'react'
import { useState, useEffect } from "react"
import moment from "moment";
import {
    CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CContainer,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModalHeader,
  CModalBody,
  CModal,
  CModalTitle,
  CModalFooter,
  CFormInput,
  CInputGroupText,
  CInputGroup,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CForm
} from '@coreui/react'

import DataGrid, {
  Column,
  FilterRow,
  Popup,
  Form,
  Grouping,
  GroupPanel,
  Pager,
  Button,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';

import { connect } from "react-redux";

import { GetAllDevices } from '../../../helpers/FetchData/DevicesData';


const UserManager = (LogUser) => {
    const [DevicesStore, setdevice] = useState();
     //LOAD DATA BEFORE RENDER
  useEffect(() => {
    //setDate(new Date());
    if (LogUser)
    GetAllDevices(LogUser.UserData).then(data => setdevice(data));
  }, []);
   
    return (
      <CRow>
       
          
        <CCol xs={12}>
        
          <CCard className="mb-4">
            <CCardHeader>
              <strong>NOCTOPARK USERS</strong> 
            </CCardHeader>
           
            <CCardBody>
                
  
  
        <CContainer>
  
 
       
          
           <CRow >
           <DataGrid className='cls'
            //ref={ref => dataGrid = ref}
            id="gridContainer"
            dataSource={DevicesStore}
            //keyExpr="id"
            allowColumnReordering={true}
            showBorders={true}
            //onRowPrepared={rowPrepared}
            //onSaving={onSaving}
            //onRowUpdated={onRowUpdated}
            /*onEditingStart={this.onEditingStart}
            onInitNewRow={this.onInitNewRow}
            onRowInserting={this.onRowInserting}
            onRowInserted={this.onRowInserted}
            onRowUpdating={this.onRowUpdating}
            onRowUpdated={this.onRowUpdated}
            onRowRemoving={this.onRowRemoving}
            onRowRemoved={this.onRowRemoved}
            onSaving={this.onSaving}
            onSaved={this.onSaved}
            onEditCanceling={this.onEditCanceling}
            onEditCanceled={this.onEditCanceled}*/>
  
            <Paging enabled={true} />
            <FilterRow visible={true} />
            <SearchPanel visible={false} />
  
          <Column
            dataField="Ip"
            caption="IP Adress"
            dataType="string"
            alignment="center"
          />
          <Column
            dataField="Config"
            caption="Configurations"
            
            alignment="center"
            
            dataType="string"
            
          >
           
          </Column>
          <Column type="buttons"
      alignment="center"
      caption="Commands"
      width={110}>
          <Button hint="Cancel Transaction" icon="fa fa-stop" visible={true} disabled={false}  />
          <Button hint="Reboot" icon="fa fa-power-off" visible={true} disabled={false}  />
          <Button hint="Cancel Transaction" icon="fa fa-trash-o" visible={true} disabled={false}  />
      </Column>
          
          
      </DataGrid>
           </CRow>
  
        </CContainer>
  
           
              
            </CCardBody>
          </CCard>
        </CCol>
        
      </CRow>
    )
  }
  
  export default connect(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    UserData: state.auth.UserData,
    Selection: state.auth.Selection
  }))(UserManager);